<template>
  <transition name="fade" mode="out-in">
    <div v-if="subscribe" class="d-flex align-center justify-center">
      <icon-check class="icon--before-text text-color-success" />
      <p class="h5 ml-1 mb-0">
        {{ $t('footer.newsletter.subscribe') }}
      </p>
    </div>
    <form
      v-else
      :class="`newsletter-${id}`"
      novalidate
      @submit.prevent="submit"
    >
      <div class="d-md-flex justify-center">
        <div class="form__elements">
          <form-input
            :id="`${id}-email`"
            ref="email"
            v-model="email"
            :label="$t('input.email.label')"
            :placeholder="$t('input.email.placeholder')"
            required
            first-submit
            type="email"
          />
          <div
            v-if="newsletterLists.length > 1"
            class="
              form__elements__checkboxes
              d-flex
              align-center
              justify-center justify-md-start
              pt-md-0-2
              pb-1 pb-md-0
            "
          >
            <input-checkbox
              v-for="(l, index) of newsletterLists"
              :id="`footer-list-${l.id}`"
              :key="l.id"
              v-model="l.checked"
              :name="`footer-list-${l.id}`"
              :class="index > 0 ? 'ml-1-5' : ''"
            >
              {{ l.name }}
            </input-checkbox>
          </div>
        </div>

        <primary-button
          type="submit"
          :text="buttonLabel ? buttonLabel : $t('action.newsletterSubscribe')"
          align="center"
          :aspect="buttonAspect"
          :disabled="disabled"
          :with-arrow="buttonArrow"
          class="ml-md-1"
          :loading="loading"
        />
      </div>

      <transition name="appear-top">
        <p v-if="errSubscribe" class="text-color-error text-small mt-1 mb-0">
          {{ $t('footer.newsletter.error') }}
        </p>
      </transition>
    </form>
  </transition>
</template>

<script>
import FormInput from '@/components/form/Input'
import InputCheckbox from '@/components/form/InputCheckbox'
import PrimaryButton from '@/components/buttons/Primary'
import IconCheck from '@/components/icons/Check'
import { ORIGIN } from '@/const'
import services from '@/services'

export default {
  name: 'FormKlaviyoEmail',
  components: {
    FormInput,
    InputCheckbox,
    PrimaryButton,
    IconCheck,
  },
  props: {
    id: { type: String, required: true },
    buttonLabel: { type: String, default: null },
    buttonAspect: { type: String, default: 'light' },
    buttonArrow: { type: Boolean, default: false },
    context: { type: String, default: null },
  },
  data() {
    return {
      email: '',
      newsletterLists: [],
      loading: false,
      subscribe: false,
      errSubscribe: false,
    }
  },
  computed: {
    disabled() {
      return !this.newsletterLists.some((l) => l.checked)
    },
  },
  watch: {
    $route(to, from) {
      if (
        to.params.context &&
        from.params.context &&
        to.params.context !== from.params.context
      ) {
        this.newsletterLists.sort((l) => (l.id !== to.params.context ? 1 : -1))
        this.newsletterLists.forEach((l) => {
          l.checked = l.id === to.params.context
        })
      }
    },
  },
  mounted() {
    const locale = this.$i18n.locales.find((l) => l.code === this.$i18n.locale)

    const list = []

    if (
      locale['klaviyoNewsletter-h'] &&
      (this.context === null || this.context === 'h')
    ) {
      list.push({
        id: 'h',
        name: 'Homme',
        checked: this.context
          ? this.context === 'h'
          : this.$route.params.context === 'h',
      })
    }
    if (
      locale['klaviyoNewsletter-f'] &&
      (this.context === null || this.context === 'f')
    ) {
      list.push({
        id: 'f',
        name: 'Femme',
        checked: this.context
          ? this.context === 'f'
          : this.$route.params.context === 'f',
      })
    }

    list.sort((l) => (l.id !== this.$route.params.context ? 1 : -1))

    this.newsletterLists = list
  },
  methods: {
    submit() {
      this.subscribeToNewsletter()
      return false
    },
    subscribeToNewsletter() {
      if (this.$refs.email && !this.$refs.email.isValid) {
        return
      }

      this.errSubscribe = false
      this.loading = true

      const locale = this.$i18n.locales.find((locale) => {
        return locale.code === this.$i18n.locale
      })

      this.$track.bisSubscribe(this.email, 'subscribe', ORIGIN.FOOTER)

      /* eslint-disable no-undef */
      const pp = []
      for (const l of this.newsletterLists) {
        if (l.checked) {
          pp.push(
            services.notificationCenterService.subscribeCustomerToContext({
              email: this.email,
              context: l.id === 'f' ? 'femme' : 'homme',
              locale: locale.code.substring(0, 2),
              source: 'footer',
            })
          )
        }
      }

      Promise.all(pp)
        .then(() => {
          this.loading = false
          this.subscribe = true
        })
        .catch((err) => {
          this.$logError.captureException(err)
          this.errSubscribe = true
        })
        .finally(() => {
          this.loading = false
        })

      /* eslint-enable no-undef */
    },
  },
}
</script>
